import React, { useState } from "react";
////Import state management provider
import { useMyContext } from "../../../Context/context";
//A custom title
import {
  SectionTitle,
  VerticalTimeline,
  VerticalTimelineElement,
  FaLandmark,
  Accordion,
  FaLink
} from "@lexis/ui-library";


//Import styles
import "./styles.scss";

//Import componenets
import AccordionContent from "./VerticalTimeLineComponents/AccordionContent/AccordionContent";
import AdvancedFilter from "../../Reusable/Filters/AdvancedFilter";
import ExternalLink from "./VerticalTimeLineComponents/ExternalLink/ExternalLink"
//Import component to render the results from the search using advanced filter toolbar
import SearchResults from "../../Reusable/SearchResults/SearchResults";
//Import helper for sorting array of objects
import sortBy from 'underscore/modules/sortBy'

export default function Subcategories(props) {
  //Load states from state management provider
  const { setSelectedCategoria, filteredBooks, libros, materias } = useMyContext();
  //Define local states
  const [content, setContent] = useState(null);
  const [categoryData, setCategoryData] = useState(null)
  const [categoryBooks, setCategoryBooks] = useState([]);
  const [filteredCategoryBooks, setFilteredCategoryBooks] = useState([]);
  const [accordionSubjects, setAccordionSubjects] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null)
  const [orderedSubcategories, setOrderedSubcategories] = useState(null)

  React.useEffect(() => {
    /*console.log("props.subcategorias: ", props.subcategorias);
   //console.log(
      "props.subcategorias[0].idCategoria: ",
      props.subcategorias[0].idCategoria
    );
   //console.log("libros: ", libros);
   //console.log("categoryBooks: ", categoryBooks);*/
    if (!categoryBooks.length && libros) {
      let temp = libros.filter(
        (data) => data.idCategoria === props.subcategorias[0].categoria.id
      );
      //console.log("inside categoryBooks: ", temp);
      setCategoryBooks(temp);
    }
  }, [libros]);

  React.useEffect(() => {
    //console.log( "props.subcategorias[0]",props.subcategorias[0])
    if (!categoryData) {
      setSelectedCategoria({ value: props.subcategorias[0].categoria.id, label: props.subcategorias[0].categoria.categoria })
      setCategoryData(props.subcategorias)
    }

  }, [props.subcategorias])

  React.useEffect(() => {
    setFilteredCategoryBooks(null);
    //console.log("effect content: ", content);
    let temp;
    //let textInput = null;

    if (categoryBooks && content) {

      //console.log("categoryBooks: ", categoryBooks);
      temp = categoryBooks.filter((data) => data.idSubcategoria === content.id);
      
    }


    setFilteredCategoryBooks(temp);

    if (materias && content) {
      let temp = []
      temp = materias.filter(
        (materia) =>
          materia.subcategoria.id === content.id
      )
      setAccordionSubjects(temp)
      //console.log("materias: ", temp)
    }


  }, [content]);


  React.useEffect(() => {
    //console.log(props.subcategorias)
    if (props.subcategorias && props.subcategorias !== orderedSubcategories) {
      if (props.subcategorias.length > 0) {

        !isNaN(parseInt(props.subcategorias[0].subcategoria)) ? setOrderedSubcategories(sortBy(props.subcategorias, 'subcategoria').reverse()) : setOrderedSubcategories(sortBy(props.subcategorias, 'subcategoria'))
      }
      else {
        setOrderedSubcategories(null)
      }

    }
  }, [props.subcategorias])


  return (
    <div className="row mt-0 py-4 mx-auto subcategories-container">
      <SectionTitle
        color="var(--primaryText)"
        title={props.subcategorias[0].categoria.categoria}
      />
      <div className="col-12 px-0 mt-3">
        <AdvancedFilter disableCategories={true} />
        <SearchResults />
      </div>

      <Accordion className="w-100 " onSelect={e => setSelectedKey(e)}>
        {!filteredBooks && <VerticalTimeline layout="1-column">
          {orderedSubcategories && orderedSubcategories.map((item) => {
            return (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: "#00acbd", color: "#fff" }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00acbd",
                  top: "25px",
                }}
                //date="2011 - present"
                iconStyle={{
                  background: "#00acbd",
                  color: "#ffffff",
                  fontSize: "27px",
                  width: "40px",
                  height: "40px",
                  top: "11.5px",
                }}
                icon={item.link ? <FaLink className="text-white " /> : <FaLandmark className="text-white " />}
                id={"subcategory-" + item.id}
              >
                {item.link ?
                  <ExternalLink item={item} />
                  :
                  <AccordionContent item={item} filteredCategoryBooks={filteredCategoryBooks} setContent={setContent} accordionSubjects={accordionSubjects} selectedKey={selectedKey} />

                }
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
        }
      </Accordion>
    </div>
  );
}
