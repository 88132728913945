import React from "react";
import {
  QueryClient,
  QueryClientProvider
} from "react-query"
import { ReactQueryDevtools } from "react-query/devtools";
import { MyProvider } from "./Context/context";
import Router from "./Components/Router/Router";


//A router from react-router-dom
import { BrowserRouter } from "@lexis/ui-library";

//Import custom navbar
import Navbar from "./Components/Reusable/Navbar/Navbar";

//Import custom footer
import Footer from "./Components/Reusable/Footer/Footer";

// Create a client for react-query
const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      cacheTime: 1000 * 60 * 30, //30 minutes
      refetchInterval: false,
      refetchIntervalInBackground: false,
      suspense: false,
      staleTime: 1000 * 60 * 30,
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

export default function Root(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <MyProvider>
        <BrowserRouter>
          <Navbar />
          <Router />
          <div id="single-spa-application:eclex"></div>
          <Footer />
        </BrowserRouter>
      </MyProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>

  );
}
