import React from "react";

//A custom icon
import {

  FaAngleRight,

} from "@lexis/ui-library";



export default function ExternalLink(props) {

  return (

    <a
      className="btn py-3 h-100 w-100"
      href={props.item.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="row mx-0 w-100">
        <h5 className="vertical-timeline-element-title">
          {props.item.subcategoria} 
        </h5>
        <div className="col px-0"></div>
        <FaAngleRight
          className="text-white my-auto"
          fontSize="30px"
        ></FaAngleRight>
      </div>
    </a>

  );
}
