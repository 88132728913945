import React from "react";

//Import state management provider
import { useMyContext } from "../../../Context/context";

//Import tooltip component
import { SectionTitle } from "@lexis/ui-library";

//Import styles
import "./styles.scss";

//Import list of categories
import CategoryList from "./CategoryList"

//Import About section
import About from "./About";

//Import toolbar with title, categories, subcategories and subjects filters
import AdvancedFilter from "../../Reusable/Filters/AdvancedFilter";

//Import component to render the results from the search using advanced filter toolbar
import SearchResults from "../../Reusable/SearchResults/SearchResults";

//Import blog summary component
import { BlogResumen } from "./BlogResumen";

export default function Home() {
  //Load states from state management provider
  const { filteredBooks } = useMyContext();
  return (
    <div className="row mx-auto categories-container my-3 my-xl-5">

      <div className="col-12">
        <SectionTitle color="var(--primaryText)" title="Búsqueda Avanzada" className="pb-4" />
        <AdvancedFilter />
        <SearchResults/>
      </div>

      <CategoryList/>



      {!filteredBooks && <BlogResumen />}
      {!filteredBooks && <About />}
      
    </div>
  );
}
