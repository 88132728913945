import React from 'react';
import { Link } from "@lexis/ui-library";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core"
import { useBlogData } from "./useBlogData";
import { Fragment } from "react";

//export helper for converting a html string to react components
export const HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();
export const BlogResumen = () => {
    const { data, error, isFetching } = useBlogData();

    if (isFetching) return <PacmanLoader marginTop="20vh" />;

    if (error) return <Fragment />;

    return (
        <div className="row mx-0 w-100 px-3 px-xl-5 py-4">
            <h5 className="gotham-rounded-bold w-100 py-3 mb-0">BLOG LEXIS: ESTE MES</h5>
            <div className="col-12 col-sm-4 col-lg-2 p-3 my-auto">
                <img
                    src={`https://m2admin.lexis.com.ec/assets/${data?.blog_resumen?.imagen?.id}`}
                    className="img-fluid w-100"
                    alt={`Banner de promocional del blog`}
                />
                <p className="gotham-rounded-bold text-center pt-1">
                    Foto:{" "}
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={data?.blog_resumen?.imagen_link}
                    >
                        {data?.blog_resumen?.imagen_referencia}
                    </a>
                </p>
            </div>
            <div className="col my-auto text-justify">
                {htmlToReactParser.parse(data?.blog_resumen?.resumen)}
            </div>

            <div className="w-100 text-end text-right">
                <Link
                    className="btn lhl-read-article-btn"
                    to={{ pathname: data?.blog_resumen?.documento_link }}
                    target="_blank"
                >
                    Leer Artículo
                </Link>
            </div>
        </div>
    );
};
