import React from "react";

//Import state management provider
import { useMyContext } from "../../../Context/context";

//Import tooltip component
import { SectionTitle } from "@lexis/ui-library";


//Import list of book component
import BookList from "../BooksList/BooksList";

export default function SearchResults(props) {
  //Load states from state management provider
  const {  filteredBooks } = useMyContext();
  return (
    <React.Fragment>      
      {filteredBooks && (
        <SectionTitle
          color="var(--primaryText)"
          title="Resultados de la Búsqueda"
          className="my-3"
        />
      )}
      {filteredBooks && <BookList books={filteredBooks} visibleCSM={true} />}
    </React.Fragment >
  );
}
