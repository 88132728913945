import React, { useState } from "react";

//Import components from ui library
import { Card, Link } from "@lexis/ui-library";

//Import styles
import "./styles.scss";

//Import favorito icon (star icon)
import FavoritoIcon from "../FavoritoIcon/FavoritoIcon";

////Import state management provider
import { useMyContext } from "../../../Context/context";

//Import helper for highlight searched words
import Highlighter from "react-highlight-words";

//Import helper for sorting array of objects
import sortBy from 'underscore/modules/sortBy'
//Imort helper for checking if content type is date
import isDate from 'underscore/modules/isDate'

export default function BooksList(props) {
  //Load states from state management provider
  const { textInput, categorias, subcategorias, materias } = useMyContext();
  const [libros, setLibros] = useState(null)
  const [sortedLibros, setSortedLibros] = useState(null)

  React.useEffect(() => {
    //console.log("libros on booklist: ", libros)
   //console.log("prev sortedlibros on booklist: ", sortedLibros)
    if (libros /*&& !sortedLibros*/) {
     /*console.log("libros ", libros)
      if (libros.length > 0) {
       //console.log("libros.length > 0")
        if ((libros[0].idCategoria === 8) || (libros[0].idCategoria === 1)) {

          let a = libros;
          if (!isDate(libros[0].anio)) {
            let parts;
            for (var i = 0; i < a.length; ++i) {
              if (a[i].anio) {
                parts = a[i].anio.split('/');
                a[i].anio = new Date(parts[2], parts[1] - 1, parts[0]);
              }
            }
          }

          var b = a.sort(function (a, b) {
            return a.anio - b.anio;
          });
          setSortedLibros(b.reverse())
          //setSortedLibros(libros)
         //console.log("setted by date:", b)
        }
        else if ((libros[0].idCategoria === 5)){
          
          //console.log(libros.reverse())
          let temp=sortBy(libros, 'anio')
          setSortedLibros(temp.reverse())
        }
        else if ((libros[0].idMateria === 2)||(libros[0].idMateria === 26)){
          
          //console.log(libros.reverse())
          let temp=sortBy(libros, 'titulo')
          setSortedLibros(temp.reverse())
        }
        else {
         //console.log("setting by title")
          setSortedLibros(sortBy(libros, 'titulo'))

        }
      }
      else {
       //console.log("null")
        setSortedLibros(null)
      }*/
      setSortedLibros(libros)
    }
    //console.log("post sortedlibros on booklist: ", sortedLibros)
  }, [libros])


  React.useEffect(() => {
    //console.log("props.books on booklist: ", props.books)
    if (props.books && props.books !== libros) {
      props.books.length > 0 && setLibros(props.books)
    }
  }, [props.books])

  return libros ? (
    sortedLibros ? sortedLibros.map((item) => {
      return (
        <Card className="w-100 books-card mx-auto">
          <div className="row w-100 py-2 px-3 ">
            <div className="col-2 col-sm-1 text-center">
              <FavoritoIcon item={item} />
            </div>

            <div className="col-10 col-sm-11">
              <Link
                className="my-auto book-link cursor-pointer"
                //onClick={() => setDocumentId(item.id)}
                to={{
                  pathname: "/document",
                  search: "?id=" + item.id
                }}
                target="_blank"

              >
                <Highlighter
                  highlightClassName="lhl-highlighted-words"
                  searchWords={[textInput]}
                  autoEscape={true}
                  textToHighlight={item.titulo.toUpperCase()}
                />
              </Link>
              {item.idCategoria && props.visibleCSM && (
                <p className="gotham-rounded-medium pt-2">
                  Categoría:{" "}
                  <span className="gotham-rounded-light">{categorias.filter((categoria) => categoria.value === item.idCategoria)[0].label}</span>
                </p>
              )}
              {item.idSubcategoria && props.visibleCSM && (
                <p className="gotham-rounded-medium">
                  Sub-categoría:{" "}
                  <span className="gotham-rounded-light">{subcategorias.filter((subcategoria) => subcategoria.value === item.idSubcategoria)[0].label}</span>
                </p>
              )}
              {item.idMateria && props.visibleCSM && (
                <p className="gotham-rounded-medium">
                  Materia:{" "}
                  <span className="gotham-rounded-light">{materias.filter((materia) => materia.value === item.idMateria)[0].label}</span>
                </p>
              )}
              {item.autor && (
                <p className="gotham-rounded-medium">
                  Autor:{" "}

                  <span className="gotham-rounded-light">
                    <Highlighter
                      highlightClassName="lhl-highlighted-words"
                      searchWords={[textInput]}
                      autoEscape={true}
                      textToHighlight={item.autor}
                    />
                  </span>
                </p>
              )}
              {item.anio && item.idCategoria !== 8 && (
                <p className="gotham-rounded-medium">
                  Fecha:{" "}
                  <span className="gotham-rounded-light">{isDate(item.anio) ? item.anio.toLocaleDateString() : item.anio}</span>{" "}
                </p>
              )}
              {/*item.tema && (
                <p className="gotham-rounded-medium">
                  Tema:{" "}
                  <span className="gotham-rounded-light">
                    <Highlighter
                      highlightClassName="lhl-highlighted-words"
                      searchWords={[textInput]}
                      autoEscape={true}
                      textToHighlight={item.tema}
                    />
                  </span>
                  {" "}
                </p>
              )*/}
              {item.editorial && (
                <p className="gotham-rounded-medium">
                  Editorial:{" "}
                  <span className="gotham-rounded-light">
                    <Highlighter
                      highlightClassName="lhl-highlighted-words"
                      searchWords={[textInput]}
                      autoEscape={true}
                      textToHighlight={item.editorial}
                    />
                  </span>{" "}
                </p>
              )}
              {item.pais && (
                <p className="gotham-rounded-medium">
                  País:{" "}
                  <span className="gotham-rounded-light">{item.idPais}</span>{" "}
                </p>
              )}
            </div>
          </div>
        </Card>
      );
    })
      :
      <Card
        className="w-100 books-card"
        style={{ backgroundColor: "var(--primaryBg)" }}
      >
        <p className="text-center w-100">No existen datos disponibles</p>
      </Card>
  ) : (
      <Card
        className="w-100 books-card"
        style={{ backgroundColor: "var(--primaryBg)" }}
      >
        <p className="text-center w-100">No existen datos disponibles</p>
      </Card>
    );
}
