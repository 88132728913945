import { useQuery } from "react-query";
import { gql, request } from "graphql-request";

const endpoint = "https://m2admin.lexis.com.ec/graphql";

const GET_BLOG_SUMMARY = gql`
  query {
    blog_resumen 
    {
        imagen{
            id
        },
        imagen_referencia,
        imagen_link,
        resumen,
        documento_link
    }
  }`;

export function useBlogData() {
  return useQuery("blog-summary", async () => {
    const resp = await request(endpoint, GET_BLOG_SUMMARY);
    return resp;
  });
}
