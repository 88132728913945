import React from "react";

function Icon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0"
            y="0"
            enableBackground="new 0 0 612 555.2"
            version="1.1"
            viewBox="0 0 612 555.2"
            width={props.width}
            fill="#fff"
            xmlSpace="preserve"
        >
            <path d="M94.8 34.5H601.6999999999999V109.3H94.8z" className="st0"></path>
            <path
                d="M10.8 137.3H517.6999999999999V212.10000000000002H10.8z"
                className="st0"
            ></path>
            <path d="M94.8 240.2H601.6999999999999V315H94.8z" className="st0"></path>
            <path d="M10.8 343H517.6999999999999V417.8H10.8z" className="st0"></path>
            <path
                d="M94.8 445.8H601.6999999999999V520.6H94.8z"
                className="st0"
            ></path>
            <path d="M10.3 34.5H85.1V109.3H10.3z" className="st0"></path>
            <path d="M10.3 445.8H85.1V520.6H10.3z" className="st0"></path>
            <path d="M10.3 240.2H85.1V315H10.3z" className="st0"></path>
            <path
                d="M526.9 137.3H601.6999999999999V212.10000000000002H526.9z"
                className="st0"
            ></path>
            <path
                d="M526.9 343H601.6999999999999V417.8H526.9z"
                className="st0"
            ></path>
            <image
                width="1050"
                height="700"
                overflow="visible"
                transform="translate(-7886 -7775.31)"
                xlinkHref="E8DA976C.jpg"
            ></image>
        </svg>
    );
}

export default Icon;