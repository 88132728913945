import React, { useState } from 'react'
//Components from Lexis UI Library
import {
    FaAngleRight,
    FaAngleDown,
    Accordion,
    Card, Collapse, scroller

} from "@lexis/ui-library";

//Import styles
import "./styles.scss";

import BookList from "../../../../../Reusable/BooksList/BooksList";
export default function SubjectsList(props) {
    const handleScroll = () => {
        
        scroller.scrollTo("subcategory-" + props.parentId, {
            duration: 1500,
            delay: 300,
            smooth: true,
            offset: -50, // Scrolls to element + 50 pixels down the page     
        })
    }
    return (
        <React.Fragment id={"subject-" + props.subject.id}>
            <Card>

                <Accordion.Toggle className="lhl-subject-accordion-toggle px-3 py-3" eventKey={props.subject.id}>
                    <div className="row mx-0 w-100">
                        {props.selectedKey === props.subject.id ?
                            <FaAngleDown
                                className="my-auto pr-3"
                                fontSize="25px"
                            ></FaAngleDown> :
                            <FaAngleRight
                                className="my-auto pr-3"
                                fontSize="25px"
                            ></FaAngleRight>
                        }
                        <h6 className="my-auto">
                            {props.subject.label}
                        </h6>


                    </div>

                </Accordion.Toggle>

                <Collapse
                    in={props.subject.id === props.selectedKey}
                    
                    className="subcategories-accordion-body"
                    onEntered={() => handleScroll(props.subject.id)}>


                    <div className="row mx-0 w-100">
                        <BookList books={props.books} />
                    </div>

                </Collapse>
            </Card>

        </React.Fragment>
    )
}
