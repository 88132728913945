import React, { useState } from "react";

//Import state management provider
import { useMyContext } from "../../../Context/context";

//Import components from lexis ui library
import { isMobile } from "@lexis/ui-library";

//Import image
import FotoLhl from "./fotoLHL.jpg";

//Import styles
import "./styles.scss";

var textoCompleto = (
  <p>
    Biblioteca Virtual LHL, denominada así en honor al Fundador de LEXIS; el Dr.
    Luis Hidalgo López, Gerente y Presidente de la Empresa. El doctor Hidalgo
    fue un reconocido jurista, investigador social, consultor internacional,
    hombre de ciencia, empresario, visionario emprendedor y sobre todo
    profundamente humanista. Comenzó en la década de los años 60 a recopilar
    información del Registro Oficial en base a fichas nemotécnicas; información
    recogida con tal acuciosidad que sirvió de base para que en los años 80s la
    incipiente ciencia de las computadoras personales recogiera en programas
    diseñados por profesionales ecuatorianos.<br></br> <br></br>
    La evolución de las herramientas de búsqueda en textos impulsada por el Dr.
    Luis Hidalgo nos ha llevado al día de hoy a la utilización de tecnología de
    punta en la prestación de consulta de información documental y jurídica en
    la web de todas nuestras líneas de servicios que se ajustan a cada una de
    las necesidades de la sociedad. Lexis rinde un homenaje al creador de la
    primera base de datos jurídica más completa de Latinoamérica con la puesta
    en marcha de esta Biblioteca Virtual, cuyo concepto evolutivo con diseño
    minimalista de textos en formato PDF e imágenes, además de crear un
    repositorio académico, permitirá realizar intercambios de información vía
    documentos de autoría personal de nuestros clientes, junto con la
    posibilidad de investigar en textos, será también una vía para ingresar a
    las bibliotecas multitemáticas de mayor prestigio a nivel mundial.
  </p>
);
var TextHint =
  "Biblioteca Virtual LHL, denominada así en honor al Fundador de LEXIS; el Dr. Luis Hidalgo López, Gerente y Presidente de la Empresa.";

export default function About() {
  const { proAccess } = useMyContext();
  const [text, setText] = useState(TextHint);
  const [showCompleteText, setShowCompleteText] = useState(false);
  const [btnText, setBtnText] = useState("Leer más");

  const updateText = (state) => {
    if (state === false) {
      setText(textoCompleto);
      setBtnText("Ocultar");
      setShowCompleteText(true);
    } else {
      setText(TextHint);
      setBtnText("Leer más");
      setShowCompleteText(false);
    }
  };
  return (
    <div className="pt-4  mt-4 my-xl-5 aboutBg  py-2 py-lg-3 py-xl-4  " fluid>
      <div className="row mx-0 w-100 aboutTitle">
        <div className="col pl-0 offset-xl-2 ">
          <h3 className="aboutTitle px-2 px-lg-3 px-xl-4 my-3 text-center text-xl-left">
            Sobre la Biblioteca LHL{" "}
            {proAccess && (
              <span style={{ color: "var(--mainInfolex" }}>PRO</span>
            )}
          </h3>
        </div>
      </div>
      <div className="row mx-0 w-100 aboutTitle">
        <div className="col-12 col-xl-2 py-2 py-lg-3 py-xl-4 my-auto  text-center">
          <img
            src={FotoLhl}
            alt="Foto LHL"
            className="img-fluid w-100 aboutFoto text-center text-xl-left"
          />
        </div>

        <div className="col px-0 my-auto py-2">
          <h5 className="d-none d-xl-block aboutText px-2 px-lg-3 px-xl-4">
            {textoCompleto}
          </h5>

          <div className="d-block d-xl-none row mx-0 w-100">
            <p className="aboutText px-2 px-lg-3 px-xl-4">
              {text}
              <button //link to pdf asset
                className="aboutBtn"
                onClick={() => updateText(showCompleteText)}
              >
                {btnText}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
