import React, { useState } from "react";

//Components from Lexis UI Library
import {
  FaAngleDown,
  Accordion,
  scroller, Collapse
} from "@lexis/ui-library";


//Import styles
import "../../styles.scss";

//Import componenets
import BookList from "../../../../Reusable/BooksList/BooksList";
import SubjectsList from "./Subjects/SubjectsList";

//Import helper for sorting array of objects
import sortBy from 'underscore/modules/sortBy'
//Imort helper for checking if content type is date
import isNumber from 'underscore/modules/isNumber'

export default function AccordionContent(props) {

  const [subjectBooks, setSubjectBooks] = useState(null)
  const [selectedKey, setSelectedKey] = useState(null)
  const [orderedSubjects, setOrderedSubjects] = useState(null)



  const handleAccordionSelect = (e) => {
    setSubjectBooks(null)
    setSelectedKey(e)
   //console.log("handle accordion select", e)
    props.filteredCategoryBooks && setSubjectBooks(props.filteredCategoryBooks.filter(book => book.idMateria === e))
  }
  React.useEffect(() => {
    //console.log(subjectBooks)
  }, [subjectBooks])

  const handleScroll = (id) => {
    setSubjectBooks(null)
    scroller.scrollTo("subcategory-" + id, {
      duration: 1500,
      delay: 300,
      smooth: true,
      offset: -50, // Scrolls to element + 50 pixels down the page     
    })
  }

  React.useEffect(() => {

    if (props.accordionSubjects && props.accordionSubjects !== orderedSubjects) {
      if (props.accordionSubjects.length > 0) {
        
        !isNaN(parseInt(props.accordionSubjects[0].materia))? setOrderedSubjects(sortBy(props.accordionSubjects, 'materia').reverse()):setOrderedSubjects(sortBy(props.accordionSubjects, 'materia'))
      }
      else {
        setOrderedSubjects(null)
      }

    }
  }, [props.accordionSubjects])
  return (

    <React.Fragment>
      <Accordion.Toggle
        eventKey={props.item.id}
        className="subcategories-accordion-toggle"
      >
        <button
          className="btn py-3 h-100 w-100"
          onClick={() => props.setContent(props.item)}
          id={"subcategory-" + props.item.id}
        >
          <div className="row mx-0 w-100">
            <h5 className="vertical-timeline-element-title">
              {props.item.subcategoria}
            </h5>
            <div className="col px-0"></div>
            <FaAngleDown
              className="text-white my-auto"
              fontSize="30px"
            ></FaAngleDown>
          </div>
        </button>
      </Accordion.Toggle>
      <Collapse
        in={props.item.id === props.selectedKey}
        id={"subcategory-" + props.item.id}
        className="subcategories-accordion-body"
        onEntered={() => handleScroll(props.item.id)}
      >
        {props.accordionSubjects.length == 0 ? <div className="row mx-0 w-100">
          <BookList books={props.item.id === props.selectedKey ? props.filteredCategoryBooks : null} />
        </div> :
          <Accordion onSelect={e => handleAccordionSelect(e)} >
            {
              orderedSubjects&&orderedSubjects.map(item => <SubjectsList key={item.id} subject={item} books={selectedKey === item.id ? subjectBooks : null} selectedKey={selectedKey} parentId={props.item.id} />)
            }
          </Accordion>

        }
      </Collapse>
    </React.Fragment>

  );
}
