import React, { useState } from "react";
//Import components form ui library
import { PhoneInput, isValidPhoneNumber } from "@lexis/ui-library";
//Import pdf asset
import Pdf from "./Derechos-autor.pdf";
//Import styles
import "./styles.scss";
export default function Footer() {
  const [value, setValue] = useState();

  //submit data from form
  const handleSubmitForm = (e) => {
    //Submit only is phone input is valid
    let flag;

    value && isValidPhoneNumber(value) ? (flag = true) : (flag = false);
    if (flag) {
     //console.log("sent");
    } else {
      e.preventDefault();
      alert("Por favor ingrese un número de teléfono válido");
    }
  };
  return (
    <div className="col w-100 Footer mt-4">
      <a //Link to pdf asset
        className="gotham-rounded-medium"
        href={Pdf}
        target="_blank"
        rel="noopener noreferrer"
      >
        <h4 className="FooterLink pt-4 pb-4 px-3">
          Formulario sobre derechos de autor
        </h4>
      </a>

      {/**Form linked to zoho forms */}
      <form
        action="https://forms.zohopublic.com/lexissa/form/FormularioBibliotecaLHL/formperma/pkaqHMpih0U_2k2L9pbXI3Y6Au8lcIoM51X0um96n6g/htmlRecords/submit"
        name="form"
        id="form"
        method="POST"
        acceptCharset="UTF-8"
        encType="multipart/form-data"
        className="needs-validation mb-1"
        onSubmit={(e) => handleSubmitForm(e)}
      >
        <input type="hidden" name="zf_referrer_name" value=""></input>
        <input type="hidden" name="zf_redirect_url" value=""></input>
        <input type="hidden" name="zc_gad" value=""></input>
        <div className="row mx-0 w-100 ">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="FooterInputContainer">
              <input //Name input
                className="form-control-footer"
                placeholder="Nombre"
                type="text"
                error="wrong"
                name="Name_First"
                success="right"
                required
                minLength={3}
              />
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <div className="FooterInputContainer">
              <input //last name input
                className="form-control-footer"
                placeholder="Apellidos"
                type="text"
                error="wrong"
                name="Name_Last"
                success="right"
                required
                minLength={3}
              />
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <div className="FooterInputContainer">
              <input //email input
                className="form-control-footer"
                placeholder="Correo"
                type="email"
                error="wrong"
                name="Email"
                success="right"
                required
                minLength={3}
              />
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <div className="FooterInputContainer">
              <PhoneInput
                className="form-control-footer"
                country="EC"
                placeholder="Celular"
                value={value}
                required
                onChange={setValue}
                name="PhoneNumber_countrycode"
              ></PhoneInput>
              {/**Phone input */}
            </div>
          </div>

          <div className="col-12">
            <div className="FooterInputContainer w-100">
              <textarea //text input
                className="form-control-footer w-100"
                style={{ height: "auto" }}
                placeholder="Pregunta o Sugerencia"
                rows="3"
                error="wrong"
                name="MultiLine"
                success="right"
                required
                minLength={3}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="FooterInputContainer">
              <button //submit button
                className="btn FooterEnviarBtn mt-2 mb-4 py-2"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="col text-justify text-white pb-4" >
        <h5 className="py-3">Lexis Ecuador se permite reproducir texto públicos sin fines comerciales: los documentos que reposan en el repositorio académico Radia no tienen propósitos comerciales.</h5>
        <h5 className="mb-0">La propiedad intelectual de los textos que publicamos en Internet sigue siendo íntegramente de los autores. Lexis S.A. renuncia explícitamente a cualquier derecho por la edición o publicación electrónica de los documentos que constan, únicamente para consulta, en la Biblioteca Digital LHL.</h5>
      </div>

    </div>
  );
}
