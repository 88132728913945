//Import React Library
import React from "react";

//This components shows dropdowns to filter by category, subcategory, subject and title
export default function TextInput(props) {
  return (
    <div className="col-12">
      <input
        type="text"
        className="form-input px-4"
        onChange={(e) => props.handleChange(e.target.value)}
        onKeyDown={(e) => e.key  === "Enter" && props.value&& props.filterBooks()}
        value={props.value}
        placeholder={props.placeholder}
      />
    </div>
  );
}
