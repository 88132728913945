import React from "react";

//import react-router
import { Switch, Route } from "@lexis/ui-library";

//import Layouts
import Home from "../Layouts/Home/Home";
import CategoriesBooks from "../Layouts/CategoriesBooks/CategoriesBooks";
import Subcategories from "../Layouts/SubcategoriesBooks/Subcategories";
import Document from "../Layouts/Document/Document"
//import SubjectsBooks from "../Layouts/SubjectsBooks/SubjectsBooks"
//import Context
import { useMyContext } from "../../Context/context";
export default function MyRouter() {
  const { categorias, subcategorias } = useMyContext();
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      {categorias &&
        categorias.map((item) => {
          let path = item.categoria.toLowerCase().replace(/ /gi, "-");
          let subcategories = subcategorias
            ? subcategorias.filter(
              (subcategoria) => subcategoria.categoria.id === item.id
            )
            : [];
         //console.log("subcategories: ", subcategories)
         //console.log("subcategorias: ", subcategorias)
          return (
            <Route id={item.id} path={"/" + path}>
              {  subcategories.length > 0 ? (
                <Subcategories subcategorias={subcategories} path={path} />
              ) : (
                  <CategoriesBooks categories={item} path={path} />
                )}
            </Route>
          );
        })}

      <Route path="/document" component={Document} />
      
    </Switch>
  );
}
