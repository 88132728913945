import React from "react";
import DropdownInput from "./DropdownInput";
import TextInput from "./TextInput";
import { FaSearch, FaTrash } from "@lexis/ui-library";
import { useMyContext } from "../../../Context/context";

//Import styles
import "./styles.scss";

export default function AdvancedFilter(props) {
  const {
    resetFilters,
    filterBooks,
    textInput,
    setTextInput,
    categorias,
    subcategoriasForDropdown,
    materias,
    selectedCategoria,
    setSelectedCategoria,
    selectedSubcategoria,
    setSelectedSubcategoria,
    selectedMateria,
    setSelectedMateria,
  } = useMyContext();
  let defaultValue = [{ value: null, label: "Todas" }];
 //console.log("selectedCategoria: ",selectedCategoria)
 //console.log("selectedSubcategoria: ",selectedSubcategoria)
 //console.log("materias: ",materias)
 //console.log("subcategoriasForDropdown: ",subcategoriasForDropdown)
  return (
    <div className="row mx-0 w-100 mb-4">
      <div className="col-12 px-0 mt-3">
        <TextInput
          placeholder="Ingrese el título del documento, autor, tema o editorial"
          value={textInput}
          handleChange={setTextInput}
          filterBooks={()=>filterBooks("advanced")}          
        />
      </div>
      <div className="col-12 col-md-6 col-xl-4 mt-3">
        <DropdownInput
          placeholder="Categoría"
          value={selectedCategoria}
          handleChange={(e) => (
            setSelectedCategoria(e),
            setSelectedSubcategoria({ value: null, label: "Todas" }),
            setSelectedMateria({ value: null, label: "Todas" })
          )}
          options={defaultValue.concat(categorias&&categorias.filter(categoria=>(categoria.label!==("EcLEX")&&categoria.label!==("Bibliotecas del Mundo"))))}
          isDisabled={props.disableCategories}
        />
      </div>
      <div className="col-12 col-md-6 col-xl-4 mt-3">
        <DropdownInput
          placeholder="Sub-categoría"
          value={selectedSubcategoria}
          handleChange={(e) => (
            setSelectedSubcategoria(e),
            setSelectedMateria({ value: null, label: "Todas" })
          )}
          options={defaultValue.concat(
            subcategoriasForDropdown &&
            subcategoriasForDropdown.filter(
                (subcategoria) =>
                  subcategoria.categoria.id ===
                  (selectedCategoria ? selectedCategoria.value : null)
              )
          )}
          isDisabled={props.disableSubCategories}
        />
      </div>
      <div className="col-12 col-md-6 col-xl-4 mt-3">
        <DropdownInput
          placeholder="Materia"
          value={selectedMateria}
          handleChange={(e) => setSelectedMateria(e)}
          options={defaultValue.concat(
            materias &&
              materias.filter(
                (materia) =>
                  materia.subcategoria.id ===
                  (selectedSubcategoria ? selectedSubcategoria.value : null)
              )
          )}
          isDisabled={props.disableSubjects}
        />
      </div>

      <div className="col-12 mt-3">
        <div className="row w-100 justify-content-end mx-0">
          <button
            className="btn advanced-filter-icon mr-3"
            onClick={() => filterBooks("advanced")}
          >
            <FaSearch />
          </button>

          <button
            className="btn advanced-filter-icon mr-0"
            onClick={() => resetFilters(props.disableCategories, props.disableSubCategories, props.disableSubjects)}
          >
            <FaTrash />
          </button>
        </div>
      </div>
    </div>
  );
}
