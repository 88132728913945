import React from "react";

////Import state management provider
import { useMyContext } from "../../../Context/context";

//Import components from ui library
import { FaRegStar, FaStar } from "@lexis/ui-library";

//Import api
import { lhlSaveFavorito, lhlDeleteFavorito } from "@lexis/lhl-api";

export default function FavoritoIcon(props) {
  //Load states from state management provider
  const { favoritos, accessType, fetchFavoritos, email } = useMyContext();
  let isFavoritoBook =
    favoritos &&
    favoritos.filter((favorito) => favorito.idDocumento === props.item.id);

  const handleDelete = () => {
    lhlDeleteFavorito(isFavoritoBook[0].id).then(() => {
      fetchFavoritos();
    });
  };

  const handleSave = () => {
    lhlSaveFavorito(email, props.item.id, props.item.titulo).then(() => {
      fetchFavoritos();
    });
  };
  return favoritos && accessType?.toUpperCase()==="PRO" && email ? (
    isFavoritoBook.length ? (
      <FaStar
        style={{ color: "rgb(235, 150, 5)" }}
        onClick={() => handleDelete()}
      />
    ) : (
      <FaRegStar
        style={{ color: "rgb(235, 150, 5)" }}
        onClick={() => handleSave()}
      />
    )
  ) : (
    <React.Fragment />
  );
}
