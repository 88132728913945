import React, { useState } from "react";
////Import state management provider
import { useMyContext } from "../../../Context/context";

//A custom title
import {
  SectionTitle,
  VerticalTimeline,
  VerticalTimelineElement,
  FaLandmark,
  FaAngleDown,
  FaAngleRight,
  Card,
  Accordion,
  Link,
} from "@lexis/ui-library";

import BookList from "../../Reusable/BooksList/BooksList";

import { lhlFindLibros } from "@lexis/lhl-api";
export default function CategoriesBooks(props) {
  //Load states from state management provider
  const { libros } = useMyContext();
  //Define local states
  const [filteredBooks, setFilteredBooks] = useState(null);
  const [books, setBooks] = useState(null);
  React.useEffect(() => {
   //console.log("props.categories: ", props.categories);
    lhlFindLibros(props.categories.id).then((data) => {
      setBooks(data.data);
    });
  }, [props.categories]);

  React.useEffect(() => {
    //console.log("props.categories: ", props.categories)
    let temp;
    let textInput = null;
    let content = props.categories;

    if (books) {
      temp = textInput
        ? books.filter((libro) =>
            libro.titulo.toLowerCase().includes(textInput.toLowerCase())
          )
        : books;
     //console.log("temp: ", temp);
    }
    //temp = temp.filter(data => data.idMateria === selectedMateria.value) : temp

    setFilteredBooks(temp);
  }, [books]);
  return (
    <div className="row mx-0 w-100 py-3">
      <SectionTitle
        color="var(--primaryText)"
        title={props.categories.categoria}
      />
      {props.path!=="eclex-search"&&<div className="mt-3 w-100 mx-auto">
        <BookList books={filteredBooks} />
      </div>}
    </div>
  );
}
