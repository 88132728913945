import "./set-public-path";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import { registerApplication, start } from "single-spa";
const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

registerApplication({
  name: "eclex",
  app: () => window.System.import("@lexis/eclex"),
  activeWhen: ["/eclex-search"],
  //customProps: { product: "LHL-Admin", layouts: "@lexis/legislacion" }
});
start()
