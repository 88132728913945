import React, { useState } from 'react'

//import Context
import { useMyContext } from "../../../Context/context";
import { useLocation } from "@lexis/ui-library"

//Import styles
import "./styles.scss";

import { Worker } from '@react-pdf-viewer/core';
import Viewer from "./Viewer"


import { lhlDownloadLibro } from "@lexis/lhl-api";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Document() {
    const [pdfUrl, setPdfUrl] = useState()
    const [label, setLabel] = useState("Cargando")
    const { setDocumentId, documentId } = useMyContext()
    let query = useQuery();

    //console.log(documentId)
    //console.log(query.get("id"))
    React.useEffect(() => {
        !documentId && setDocumentId(query.get("id"))
    }, []);

    React.useEffect(() => {

        documentId &&
            lhlDownloadLibro(documentId).then((data) => {
                
                if (!data.data.archivo.includes(".pdf")) {
                    window.open(data.data.contenido, '_self');
                    setLabel("Verifique su carpeta de descargas.")
                    setPdfUrl(null)
                }
                else{
                    setLabel("Cargando documento")
                    setPdfUrl(data.data.contenido)
                }

            });
    }, [documentId]);
    return (
        <div className="lhl-document-loading-container">


            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                {pdfUrl ? <div
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        height: '90vh',
                    }}
                >
                    <Viewer fileUrl={pdfUrl} />
                </div>
                    :
                    <div className="my-auto">
                        <h6 className="w-100 text-center my-auto">{label}</h6>
                    </div>
                }

            </Worker>
        </div>
    )
}
