// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./op2.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lhl-header-site{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat fixed top !important;background-size:cover !important}\n", "",{"version":3,"sources":["webpack://./src/Components/Reusable/Navbar/styles.scss"],"names":[],"mappings":"AAAA,iBACI,6FAAsE,CACtE,gCAAgC","sourcesContent":[".lhl-header-site{\r\n    background: transparent url(\"./op2.png\") no-repeat fixed top!important;\r\n    background-size: cover!important;\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
