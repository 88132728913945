import React from "react";

//Import state management provider
import { useMyContext } from "../../../Context/context";

//Import tooltip component
import { ReactTooltip, Link } from "@lexis/ui-library";

//Import styles
import "./styles.scss";




export default function CategoryList(props) {
  //Load states from state management provider
  const { categorias, filteredBooks } = useMyContext();

  //Define local states
  
  return (
    categorias && (
      <div className="row mx-0 w-100 justify-content-center">
        {!filteredBooks &&
          categorias.map((item) => {            
            return (   
                <div className="col-6 col-sm-4 col-md-3 col-lg-2 pt-3  px-3">
                  {item.link ? (
                    <a
                      className="btn m-0 p-0 w-100"
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={item.iconName + "," + item.icon}
                        className="img-fluid w-100"
                        alt={"Ícono de " + item.categoria}
                        data-tip={item.tooltip}
                      ></img>
                      <ReactTooltip
                        textColor="var(--mainWhite)"
                        backgroundColor="var(--mainZone)"
                        effect="solid"
                      />
                    </a>
                  ) : (
                      <Link
                        className="btn m-0 p-0 w-100"
                        to={
                          "/" + item.categoria.toLowerCase().replace(/ /gi, "-")
                        }
                      >
                        <img
                          src={item.iconName + "," + item.icon}
                          className="img-fluid w-100"
                          alt={"Ícono de " + item.categoria}
                          data-tip={item.tooltip}
                        ></img>
                        <ReactTooltip
                          textColor="var(--mainWhite)"
                          backgroundColor="var(--mainZone)"
                          effect="solid"
                        />
                      </Link>
                    )}
                </div>
              
            );
          })}
      </div>
    )


  );
}
