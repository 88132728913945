import React, { useState, useContext } from "react";

import {
  lhlCategories,
  lhlSubcategories,
  lhlMaterias,
  lhlFindPaises,
  lhlFindLibros,
  lhlFindAllLibros,
  lhlFindFavoritos,
  lhlDownloadLibro,
  categoriesByAccessType,
} from "@lexis/lhl-api";

import { getDataFromToken } from "@lexis/lhl-auth";
// make a new context
export const MyContext = React.createContext();
export const useMyContext = () => useContext(MyContext);

// create the provider
export const MyProvider = (props) => {
  const [product, setProduct] = useState(null);
  const [email, setEmail] = useState(null);
  const [accessType, setAccessType] = useState(false);
  const [accessTypeId, setAccessTypeId] = useState(3);
  const [documentId, setDocumentId] = useState(null);

  const [categorias, setCategorias] = useState(null);
  const [subcategorias, setSubcategorias] = useState(null);
  const [subcategoriasForDropdown, setSubcategoriasForDropdown] = useState(
    null
  );
  const [materias, setMaterias] = useState(null);
  const [paises, setPaises] = useState(null);
  const [libros, setLibros] = useState(null);
  const [section, setSection] = useState("Libros");
  const [favoritos, setFavoritos] = useState(null);

  const [selectedCategoria, setSelectedCategoria] = useState({
    value: null,
    label: "Todas las categorías",
  });
  const [selectedSubcategoria, setSelectedSubcategoria] = useState({
    value: null,
    label: "Todas las sub-categorías",
  });
  const [selectedMateria, setSelectedMateria] = useState({
    value: null,
    label: "Todas las materias",
  });
  const [textInput, setTextInput] = useState(null);
  const [filteredBooks, setFilteredBooks] = useState(null);
  const [searching, setSearching] = useState(false);

  const [isDarkModeEnabled, setDarkMode] = useState(false);
  const [theme, setTheme] = useState("light");

  const updateThemeMode = (state) => {
    //console.log("is dark mode enable :", state);
    setDarkMode(state);
    if (state === true) {
      setTheme("dark");
      //console.log("setting to dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      //console.log("setting to light");
      localStorage.setItem("theme", "light");
    }
  };

  React.useEffect(() => {
    !favoritos && email && fetchFavoritos().then((data) => {});
  }, [email]);

  const fetchFavoritos = async () => {
    //console.log("email:", email);
    let temp = [];
    lhlFindFavoritos(email).then((data) => {
      //console.log("find favoritos:", data);

      setFavoritos(data.data);
    });
    return temp;
  };

  React.useEffect(() => {
    !paises &&
      lhlFindPaises().then((data) => {
        console.log("data paises", data);
        let temp = [];
        data.data.forEach((element, i) => {
          temp[i] = { value: element.id, label: element.pais };
        });
        //console.log("catalogo paises:", temp)
        setPaises(temp);
      }, []);
  }, []);

  React.useEffect(() => {
    getDataFromToken().then(({ servicio, email }) => {
      console.log("servicio, email");
      categoriesByAccessType(servicio || "GENÉRICO").then(({ state, data }) => {
        setAccessType(data[0].tipoAcceso.tipoAcceso);
        setAccessTypeId(data[0].tipoAcceso.id);
        fetchCategorias(data[0].tipoAcceso.listaCategorias);
      });

      setEmail(email);
      setProduct(servicio);
    });
  }, []);

  React.useEffect(() => {
    documentId &&
      lhlDownloadLibro(documentId).then((data) => {
        //window.open(data.data.contenido, '_self');
      });
  }, [documentId]);

  React.useEffect(() => {
    //console.log("categorias: ", categorias)
    !subcategorias &&
      categorias &&
      fetchSubcategories(categorias).then((data) => {});
  }, [categorias]);

  React.useEffect(() => {
    materias && fetchBooks().then(() => {});
  }, [materias]);

  React.useEffect(() => {
    !materias && subcategorias && fetchSubjects(subcategorias).then(() => {});
  }, [subcategorias]);

  const fetchCategorias = async (listaCategorias) => {
    let temp = [],
      i = 0;

    lhlCategories().then(({ state, data }) => {
      let cont = 0;
      let listaCategoriasId = [];
      listaCategorias.forEach((element) => {
        return listaCategoriasId.push(element.id);
      });
      var filteredCategories = data?.filter((categoria) =>
        listaCategoriasId.includes(categoria.id)
      );
      filteredCategories?.forEach((element, i) => {
        temp[cont] = {
          value: element.id,
          label: element.categoria,
          ...element,
        };
        cont++;
      });
      setCategorias(temp);
    });

    return temp;
  };

  const fetchSubcategories = async (tempCategories) => {
    let temp = [];

    lhlSubcategories().then((data) => {
      //console.log("catalogo subcategorias:", data)
      let cont = 0;
      data?.data?.forEach((element, i) => {
        temp[cont] = {
          value: element.id,
          label: element.subcategoria,
          ...element,
        };
        cont++;
      });
      //console.log("catalogo subcategorias mod:", temp)
      setSubcategoriasForDropdown(temp.filter((item) => !item.link));
      setSubcategorias(temp);
    });

    return temp;
  };

  const fetchSubjects = async (tempSubcategories) => {
    let temp = [];
    lhlMaterias().then((data) => {
      let cont = 0;
      data.data.forEach((element, i) => {
        temp[cont] = {
          value: element.id,
          label: element.materia,
          ...element,
        };
        cont++;
      });
      //console.log("catalogo materias:", temp)
      setMaterias(temp);
    });
  };

  const fetchBooks = async () => {
    let temp = [];
    categorias.map(
      (categoria) =>
        !categoria.link &&
        lhlFindLibros(categoria.id).then((data) => {
          //console.log("find libros:", data)
          if (data.data) {
            temp = [...temp, ...data.data];
            setLibros(temp);
          }
        })
    );
  };

  const filterBooks = (type) => {
    let temp;

    /*console.log("selectedCategoria", selectedCategoria)
   //console.log("categorias", categorias)
   //console.log("filteredSubcategorias", filteredSubcategorias)
   //console.log("textInput", textInput)*/
    if (categorias && subcategorias && materias) {
      temp = textInput
        ? libros.filter(
            (libro) =>
              libro.titulo?.toLowerCase().includes(textInput.toLowerCase()) ||
              libro.autor?.toLowerCase().includes(textInput.toLowerCase()) ||
              libro.tema?.toLowerCase().includes(textInput.toLowerCase()) ||
              libro.editorial?.toLowerCase().includes(textInput.toLowerCase())
          )
        : libros;
      //console.log(temp)
      if (type === "advanced") {
        temp = selectedCategoria.value
          ? temp.filter((data) => data.idCategoria === selectedCategoria.value)
          : temp;
        temp = selectedSubcategoria.value
          ? temp.filter(
              (data) => data.idSubcategoria === selectedSubcategoria.value
            )
          : temp;
        temp = selectedMateria.value
          ? temp.filter((data) => data.idMateria === selectedMateria.value)
          : temp;
      }
    } else {
      temp = libros;
    }
    //console.log("setFilteredBooks", temp);
    setFilteredBooks(temp);
  };

  const resetFilters = (
    disableCategories,
    disableSubCategories,
    disableSubjects
  ) => {
    setTextInput("");
    !disableCategories &&
      setSelectedCategoria({ value: null, label: "Todas las categorías" });
    !disableSubCategories &&
      setSelectedSubcategoria({
        value: null,
        label: "Todas las sub-categorías",
      });
    !disableSubjects &&
      setSelectedMateria({ value: null, label: "Todas las materias" });
    setFilteredBooks(null);
  };

  return (
    <MyContext.Provider
      value={{
        product,
        email,
        accessType,
        paises,
        categorias,
        setCategorias,
        subcategorias,
        setSubcategorias,
        materias,
        setMaterias,
        libros,
        setLibros,

        section,
        setSection,
        fetchCategorias,
        fetchSubcategories,
        fetchSubjects,
        fetchBooks,
        theme,
        isDarkModeEnabled,
        updateThemeMode,
        favoritos,
        fetchFavoritos,
        documentId,
        setDocumentId,
        selectedCategoria,
        setSelectedCategoria,
        selectedSubcategoria,
        setSelectedSubcategoria,
        selectedMateria,
        setSelectedMateria,
        textInput,
        setTextInput,
        filteredBooks,
        setFilteredBooks,
        filterBooks,
        resetFilters,
        subcategoriasForDropdown,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};
